main {
  width: 380px;
  overflow: hidden;
  background-color: #f1f1f1;
  font-size: 16px;
  line-height: 22px;
  color: #000;
  border-radius: 7px;
  box-shadow: 0 0 14px white;
}

.top-card {
  width: 100%;
  padding: 5px;
  color: black;
}

.top-card img {
  /* padding: 10px; */
  width: 45%;
  border-radius: 50%;
  align-items: center;
  margin-left: 28%;
  border: 6px solid #62079e;
 
}

.top-card .menu-icon {
  position: relative;
  width: 22px;
  bottom: 13.7em;
  left: 1.1em;
  cursor: pointer;
}

.top-card .menu-icon .menu {
  position: relative;
  display: block;
  width: 100%;
  height: 4px;
  background-color: #000;
  margin-bottom: 6px;
  opacity: .5;
  transition: .4s;
}

.top-card .menu-icon .item1 {
  left: -5px;
}

.top-card .menu-icon .item2 {
  left: 5px;
}

.top-card .menu-icon:hover .menu {
  left: 0;
  border-radius: 1px;
}

h3 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
  margin: 12px 0;
  border-bottom: 3px solid #9D28EB;
  /* width: 55%; */
}

.post {
  font-size: 16px;
  color: #000;
  font-weight: 600;
  margin: 12px 0;
  border-bottom: 3px solid #9D28EB; 
}

.name {
  font-size: 18px;
  color: #000;
}

.middle-card, footer {
  margin: 5px 25px;
}

.middle-card {
  text-align: center;
}

.middle-card p {
  font-size: 12px;
}

footer {
  text-align: center;
  padding: 10px;
}

footer .social-icon {
  /* padding: 7%; */
  font-size: 20px;
  margin: 0 5%;
  color: rgba(0, 0, 0, .9);
}

.facebook:hover {
  color:#C780FA;
}

.twitter:hover {
  color:#C780FA;
}

.linkedin:hover {
  color:#C780FA;
}

.speaker-name {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.speaker-post {
  font-size: 12px;
  font-weight: 400;
}


