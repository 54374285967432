  .content h2{ 
    /* font-size:18px; */
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  
  .box{
    /* position: relative; */
    transform: translate(0%, 0%);
    width: 150px;
    height: 150px;
    background: transparent;
    box-sizing: border-box;
    overflow: hidden;
    /* box-shadow: 0 0 20px white; */
    border: 3px solid #9D28EB;
    color: white;
    padding: 20px;
  }
  
  .box:before{
    content: '';
    position:absolute;
    top:0;
    left:-100%;
    width:100%;
    height:100%;
    background: rgba(255,255,255,0.1);
    transition:0.5s;
    pointer-events: none;
  }
  
  .box:hover:before{
    left:-50%;
    transform: skewX(-5deg);
  }
  
  .box .content{
    position:absolute;
    top:15px;
    left:15px;
    right:15px;
    bottom:15px;
    border:2px solid white;
    padding:20px;
    align-items: center;
    box-shadow: 0 0 10px white;
    
  }
  
  .box span{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    
  }
  
  .box span:nth-child(1)
  {
    transform:rotate(0deg);
  }
  
  .box span:nth-child(2)
  {
    transform:rotate(90deg);
  }
  
  .box span:nth-child(3)
  {
    transform:rotate(180deg);
  }
  
  .box span:nth-child(4)
  {
    transform:rotate(270deg);
  }
  
  .box span:before
  {
    content: '';
    position: absolute;
    width:100%;
    height: 2px;
    background: #9D28EB;
    animation: animate 4s linear infinite;
  }
  
  @keyframes animate {
    0% {
    transform:scaleX(0);
    transform-origin: left;
    }
    50%
    {
      transform:scaleX(1);
    transform-origin: left;
    }
    50.1%
    {
      transform:scaleX(1);
    transform-origin: right;
      
    }
    
    100%
    {
      transform:scaleX(0);
    transform-origin: right;
      
    }
    
    
  } 