.header {
  height: auto;
  width: 100% !important;
  top: 0;
  z-index: 1001;
  transition: 0.2s ease;
  height: 70px;
  position: fixed;
  background-color: rgb(8, 1, 18);
}
.display {
  display: grid;
  grid-template-rows: auto auto;
  width: 100%;
}

.announcement {
  width: 100%;
}
.back {
   background-color:rgb(8, 1, 18);
}
.backg {
  border-bottom: 3px solid #dd7091;
}
.nav-div {
  width: 88%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}
.menu-only {
  display: flex;
  align-items: center;
  /* text-shadow: 1px 1px 3px #000000; */
}
.button {
  color: white !important;
  font-size: 1rem !important;
  outline: none;
  padding-right: 0px !important;
  padding-left: 15px !important;
}
.button:hover {
  border-bottom: 2px solid #e62b1e;
}
.button:focus {
  box-shadow: none !important;
}
.logo {
  height: 10rem;
  position: relative;
  top: -10px;
  /* margin: 10px 0; */
}
.navlink {
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}
 .navlink:hover {
  /* background-color: #bc3354 !important; */
   border-bottom: 2px solid #bc3354;
   color: #bc3354;
} 
.menu-drawer {
  display: none;
  align-items: center;
}

/* .buy-btn {
  text-decoration: none;
  border: none;
  color: white;
  padding: 5px 15px;
  line-height: 2em;
  font-size: 15px;
  text-align: center;
  background: linear-gradient(
    90deg,
    red,
    rgb(206, 45, 45),
    rgb(102, 32, 32),
    rgb(102, 22, 22)
  );
  background-size: 300%;
  border-radius: 30px;
  z-index: 1;
} */
.buy-btn:hover {
  color: bisque;
}
.navbar-log0{
  width: 200px;
}

@media screen and (max-width: 767px) {
  .menu-drawer {
    display: flex;
  }
  .menu-only {
    display: none;
  }
  .header {
    height: 70px;
  }
  .logo {
    margin: 0;
  }
  
}
