.hero {
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  /* height: 100vh; */
  background-color: rgb(8, 1, 18);
  /* padding-top: 60px; */
}
.tedx {
  background-color: rgb(8, 1, 18);
}
.text-2 {
  color: #9D28EB;
}
.text-4 {
  color: #9D28EB;
  font-weight: 600;
  font-size: 22px;
}
.text-3 {
  color: #9D28EB;
  font-size: 18px;
}
.speakers {
  background-color: rgb(28, 9, 9);
}
.highlights {
  background-color: rgb(8, 1, 18);
}
.location {
  background-color: rgb(8, 1, 18);
}
.text {
  text-align: center;
}
.text img {
  width: 100%;
  height: auto !important;
  /* height: 100vh; */
}
.theme-text {
  font-size: 40px;
}
.theme-text > sup {
  right: 15px;
}
.years {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-decoration: none;
  border-radius: 16px;
  
  color: #C780FA;
}
.years:hover {
  border: 2px solid #9D28EB;
  color: #C780FA;
}
.lead {
  font-size: 16px;
}
@media screen and (max-width: 1200px) {
  /* .hero {
    height: 500px;
  } */
  .text img {
    width: 100%;
    height: 500px;
  }
}
@media screen and (max-width: 767px) {
  .text img {
    width: 100%;
    height: 350px;
  }
  .theme-text {
    font-size: 30px;
  }
  .theme-text > sup {
    right: 10px;
  }
}
@media screen and (max-width: 1240px) {
}
@media screen and (max-width: 770px) and (min-width:630px){
}
@media screen and (max-width: 630px) {
}
@media screen and (max-width: 480px) {
  .theme-text {
    font-size: 20px;
  }
  .theme-text > sup {
    right: 7px;
  }
}
.book-ticket-button{
  background-color: #9D28EB;
  color: white;
  border: none;
  width: 200px;
  height: 80px;
  border-radius: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); */
  outline: none;
  margin: 0 auto;
}
.book-ticket-button:hover{
  background-color: #C264FF;
}