@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600;700;800;900&display=swap');

.card {
  position: relative;
  width: 206px;
  height: 300px;
  background-color: rgb(11, 9, 28);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
 
}
.card:hover {
  box-shadow: 0 0 10px white;
}
.card .content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: rgb(11, 9, 28);
  border: 1.4px solid #9D28EB;
  gap: 5px;
  overflow: hidden;
  z-index: 10;
  border-radius: 15px;
}

.card:hover .content {
  border: 1.4px solid white;
}

.card .content .icon {
  color: #7a21b6;
  width: 120px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  background: #2e2e2e;
  box-shadow: 0 0 0 4px #2e2e2e,
    0 0 0 6px #7a21b6;
  transition: 0.5s ease-in-out;
  border-radius: 50%;
}

.card:hover .content .icon {
  background: #590e8b;
  color: #2e2e2e;
  box-shadow: 0 0 0 4px #2e2e2e,
    0 0 0 300px #590e8b;
}

.card .content .text h3 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.5s ease-in-out;
}

.card:hover .content .text h3 {
  color: white;
}

.card .content .text p {
    font-size: 1.2rem;
  color: #999;
  transition: 0.5s ease-in-out;
}

.card:hover .content .text p {
  color: white;
}

.card .content .text a {
  position: relative;
  display: inline-block;
  /* background: #C21010; */
  color: #2e2e2e;
  text-decoration: none;
  font-weight: 500;
  /* margin-block-start: 10px;
  padding: 5px 10px; */
}

.card:hover .content .text a {
  border-radius: 100%;
  background: #2e2e2e;
  color: #7a21b6;
}

.icons {
    display: flex;
    flex-direction: row;
    row-gap: 15px;
}
.icons_head {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-start
}

.icon img{
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
/* .icons > div{
  height: 10px;
  width: 10px;
} */

.contact_icons a{
  width: 40px !important;
  height: 40px !important;
}

.mail_icon{
  border-radius:50%; 
  background-color:#E5E4E2;
  height: 40px;
}
.mail_icon a svg{
  padding-left: 4px;
  padding-top: 4px;
}