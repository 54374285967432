.flex-container {
  display: flex;
  flex-direction: column;
  column-gap: 15px;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  gap: 20px;
}
.space-between {
  justify-content: space-between;
}
.team {
  background-color: rgb(11, 9, 28);;
}
.col {
  width: 50%;
}
.card-main {
  border: 1.4px solid #9D28EB;
  color: #fff;
  border-radius: 15px;
  padding: 20px;
  background-color: rgb(11, 9, 28);
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  /* box-shadow: 1px 1px 10px -6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 1px 10px -6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 1px 10px -6px rgba(0, 0, 0, 0.5); */
}
.card-outer {
  border: 2px solid #9D28EB;
  border-radius: 15px;
  padding: 20px;
  background-color: rgb(11, 9, 28);
  display: flex;
  column-gap: 20px;
  box-shadow: 1px 1px 16px -6px white;
  -webkit-box-shadow: 1px 1px 16px -6px white;
  -moz-box-shadow: 1px 1px 16px -6px white;
}
.card-main img {
  max-width: 250px;
}
.img-placeholder {
  position: relative;
  max-height: 200px;
  height: 200px;
  width: 200px;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 1px 1px 10px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 1px 1px 10px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 1px 1px 10px -6px rgba(0, 0, 0, 0.75);
}
h3 {
  font-weight: 400;
}
p {
  font-weight: 300;
}
a {
  color: #888;
  text-decoration: none;
}
a:hover {
  color: inherit;
}
.carouselContainer {
  height: 300px;
  width: 100%;
}
.react-multi-carousel-track {
  padding-left: calc(50% - 489px);
  /* padding-right: 100px; */
}
.icons {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.mem2{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* justify-content: space-between; */
}
.img-placeholder img {
  display: block;
  width: 200px;
  height: 200px;
  object-fit: cover;
}
/* .icons > *{
  height: 10px;
} */
h3{
  font-size: 30px;
  color: white;
}
.head_name{
  font-size: 25px;
}
.icons{
  justify-content: center;
  align-items: center;
}
.onlyheaddiv{
  display: grid;
  /* width: 100%; */
  grid-column-gap: 30px;
  grid-template-columns: calc(50% - 25px) calc(50% - 25px);
}

.contact_icons a{
  width: 40px !important;
  height: 40px !important;
}
@media only screen and (min-width: 1024px) and (max-width: 1220px) {
  .react-multi-carousel-track {
    padding-left: calc(16.49% - 80.88px);
    /* padding-left: 0; */
  }
  /* .react-multi-carousel-item{
      width: 300px !important;
    } */
}
@media only screen and (min-width: 768px) and (max-width: 1250px){
  .react-multi-carousel-track {
    padding-left: calc(26.5% - 115px);
  }
}
@media only screen and (min-width: 768px){
  .col {
    width: 70%;
  }
  .col div h3{
    font-size: 25px;
  }
  .col div p {
    font-size: 20px;
  }
  .head_name{
    font-size: 20px;
  }
  .onlyHeadHead{
    font-size: 25px;
  }
  .img-placeholder{
    height: 180px !important;
    width: 180px !important;
  }
  .img-placeholder img{
    height: 180px;
    width: 180px;
  }
}
@media only screen and (max-width: 767.5px) {
  .col {
    width: 80%;
  }
  .col div p {
    font-size: 1.5rem;
  }
  .react-multi-carousel-track {
    /* padding-left: 35%; */
    padding-left: calc(50% - 103px);
  }
  .head_name{
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 500px) {
  .card-main {
    flex-direction: column;
  }
}
@media only screen and (max-width: 940px) {
  .onlyheaddiv {
    grid-template-columns: auto;
  }
}