/* Responsive typography */
@media screen and (min-width: 992px) {
  :root {
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}
/* BASE */
.modal-content{
  background-color: #5b0694;
  color: white;
  text-align: center;
}
.modal-header{
  border: 0;
}
.merch_data{
  
  align-items: center;
  justify-content: center;
  padding-bottom: 5px;
}
.btn-close{
  color: white;
}
a {
  text-decoration: none;
  width: fit-content;
}
img {
  max-width: 100%;
  height: auto;
}

.button-11 a{
  color: white;
}
.section-12 {
  padding: 0 0 3rem;
  background-color: rgb(11, 9, 28);
  color: white;
  /* width: fit-content; */
}
.section-11 {
  /* padding: 0 0 3rem; */
  background-color: rgb(11, 9, 28);
  color: white;
  width: fit-content;
}
.section_title {
  font-size: 3rem;
  margin-bottom: var(--mb-2);
  text-align: center;
}

.container {
  max-width: 968px;
  margin-left: var(--mb-1-5);
  margin-right: var(--mb-1-5);
}
.grid {
  display: grid;
}

.button-11 {
    background-color: #C780FA;
    color: white;
    padding: 1rem 3rem;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
.button-11:hover{
    border: 1px solid white; 
    cursor:pointer;
    box-shadow: 0 0 20px #C780FA;
}

.merch_container {
  row-gap: 2rem;
}
.merch_data {
  text-align: center;
}
.merch_description {
  margin-bottom: 2rem;
}
.merch_img {
  width: 180px;
  justify-self: center;
  animation: floating 2s ease-in-out infinite;
}
.merch_img2 {
  /* width: 180px; */
  justify-self: center;
  animation: floating 2s ease-in-out infinite;
}

/*  BREAKPOINTS */
/* For small devices */
@media screen and (max-width: 320px) {
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}
/* For medium devices */
@media screen and (min-width: 576px) {
  .merch_container {
    grid-template-columns: 0.8fr;
    justify-content: center;
  }
}
@media screen and (min-width: 767px) {

  .section-11 {
    padding: 0 0 3rem;
  }
  .merch_container {
    /* grid-template-columns: repeat(2, 1fr); */
    align-items: center;
  }
  /* .about__title,
  .merch_data {
    text-align: initial;
  } */
  .merch_img {
    width: 250px;
  }
}
/* For large devices */
@media screen and (min-width: 992px) {
  .container {
    margin-left: auto;
    margin-right: auto;
  }
  .merch_container {
    column-gap: 7rem;
  }
  .merch_img {
    width: 350px;
  }
  .merch_description {
    padding-right: 2rem;
  }
}
/* KEYFRAMES */
@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 15px);
  }
  100% {
    transform: translate(0, -0px);
  }
}
