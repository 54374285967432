/* .notify-bar {
    margin: 0;
    width: 100%;
    background-color: #5b0694;
    height: 100px;
    box-shadow: 0 2px 2px 1px rgb(0,0,0,.3);
    overflow: hidden;
    text-align: center;
    position: relative;
    perspective: 100px;
    transform-style: preserve-3d;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .log{
    height: 40px;
    display: inline-block;
  }
  div.notify-bar span.txt {
    display: block;
    line-height: 40px;
    font-size: 30px;
    z-index:10;
    color: white;
  }
  div.notify-bar button {
    background-color: #C780FA;
    color: white;
    padding: .1rem 1rem;
    margin-left: 4rem;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
  }
  div.notify-bar button:hover{
    border: 1px solid white; 
    cursor:pointer;
    box-shadow: 0 0 20px #C780FA;
}
  div.plank-box {
    position: absolute;
    top: 0;
    bottom:0;
    left:0;
  }
  span.planks {
    position: relative;
    display: block;
    width: 100px;
    
    z-index:-1;
  }
  span.planks:before {
    content: "";
    position: absolute;
    top:35px;
    z-index:-1;
    -ms-transform: rotate(20deg); 
    -webkit-transform: rotate(20deg); 
    transform: rotate(-40deg);
    width: 105px;
    height: 30px;
  
  }
  

  @media only screen and (max-width: 1280px) {
    .log{
      height: 25px;
    }
    
  div.notify-bar span.txt {
    font-size: 20px;
  }
  .notify-bar{
    height: 80px;
  }
}
@media screen and (max-width: 770px){
    .notify-bar{
      height: 100px;
    }

  }
  @media only screen and (max-width: 630px) {
    
    .log{
      height: 20px;
    }
    
  div.notify-bar span.txt {
    font-size: 15px;
  }
  } */


  #hellobar-bar {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    margin: 0;
    height: 30px;
    display: table;
    font-size: 17px;
    font-weight: 400;
    padding: .33em .5em;
    -webkit-font-smoothing: antialiased;
    color: white;
    position: fixed;
    background-color: rgb(11, 9, 28);
    box-shadow: 0 1px 3px 2px rgba(0,0,0,0.15);
}
#hellobar-bar.regular {
    height: 30px;
    font-size: 14px;
    padding: .2em .5em;
}
.hb-content-wrapper {
    text-align: center;
    text-align: center;
    position: relative;
    display: table-cell;
    vertical-align: middle;
}
.hb-content-wrapper p {
    margin-top: 0;
    margin-bottom: 0;
}
.hb-text-wrapper {
    margin-right: .67em;
    display: inline-block;
    line-height: 1.3;
}
.hb-text-wrapper .hb-headline-text {
    font-size: 1em;
    display: inline-block;
    vertical-align: middle;
}
#hellobar-bar .hb-cta {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 0;
    color: #ffffff;
    background-color: #C780FA;
    border-color: #22af73;
}
.hb-cta-button {
    opacity: 1;
    color: #fff;
    display: block;
    cursor: pointer;
    line-height: 1.5;
    max-width: 22.5em;
    text-align: center;
    position: relative;
    border-radius: 8px;
    white-space: nowrap;
    margin: 1.75em auto 0;
    text-decoration: none;
    padding: 0;
    overflow: hidden;
}
.hb-cta-button:hover {
  border: 1px solid white; 
    cursor:pointer;
    box-shadow: 0 0 20px #C780FA;
}
.hb-cta-button .hb-text-holder {
    border-radius: inherit;
    padding: 5px 15px;
}