.site-footer {
  margin: 0;
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  background-color: rgb(11, 9, 28);
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}
.site-footer h6 {
  font-size: 17px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
}
.site-footer a {
  color: #fff;
}
.site-footer a:hover {
  color: rgb(230, 43, 30) !important;
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #C780FA !important;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.site-footer .social-icons {
  text-align: center;
}
.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin-left: 8px;
  margin-right: 0;
  border-radius: 0%;
  
}
.copyright-text {
  margin: 0;
}
.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  text-align: start;
  margin: auto;
  list-style: none;
  display: grid;
  grid-template-columns: 60px 60px 60px;
  grid-row: auto;
  grid-column-gap: 14px;
  grid-row-gap: 8px;
  justify-content: center;
  align-items: center;
}

.social-icons li {
  display: flex;
  margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color:black;
  font-weight: 700;
  font-size: 13px;
  background-color: black;
}
.social-icons a {
  
  padding: 5px;
  
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  border-radius: 8px;
  color: #fff !important;
  background-color: #C780FA !important;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: rgb(230, 43, 30) !important;
}
.social-icons a.twitter:hover {
  background-color: rgb(230, 43, 30) !important;
}
.social-icons a.linkedin:hover {
  background-color: rgb(230, 43, 30) !important;
}
.social-icons a.instagram:hover {
  background-color: rgb(230, 43, 30) !important;
}

a {
  text-decoration: none;
}

i:active,
i:hover,
i:focus {
  color: white;
}
.tedx_img{
  width:200px;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}
@media only screen and (min-width: 767px) and (max-width: 815px) {
  .tedx_img{
    width: 180px;
  }
}