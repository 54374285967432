.card-main {
  background-color: rgb(11, 9, 28);
}

.gridCont {
  display: grid;
  /* width: 100%; */
  grid-column-gap: 50px;
  grid-template-columns: calc(50% - 25px) calc(50% - 25px);
}
.gridCont2 {
  /* background-color: black; */
  display: grid;
  /* width: 100%; */
  grid-column-gap: 0.5%;
  grid-template-columns: 33% 33% 33%;
}
h4 {
  width: 100%;
  font-size: 30px;
  border-bottom: 3px solid #9D28EB;
}
h5 {
  font-size: 30px;
  border-bottom: 3px solid #9D28EB;
}
.head_name{
    font-size: 25px;
}
.curator_name{
  font-size: 25px;
}
/* .curator_img{
  height: 150px !important;
  width: 150px !important;
} */
.card-main{
  align-items: center;
}
.contact_icons a{
  width: 40px !important;
  height: 40px !important;
}
@media only screen and (max-width: 940px) {
  .gridCont {
    grid-template-columns: auto;
  }
}
@media only screen and (max-width: 1180px) {
  .gridCont2{
    grid-template-columns: 24% 24% 5% 24% 24%;
    grid-row-gap: 5px;
  }
  .grid_1{
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .grid_2{
    grid-column-start: 4;
    grid-column-end: 6;
  }
  .grid_3{
    grid-column-start: 2;
    grid-column-end: 5;
  }
  h5{
    font-size: 25px;
  }
  .curator_name{
    font-size: 15px;
  }
}
@media only screen and (min-width: 900px) {
  .org_card1{
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .org_card2{
    grid-column-start: 2;
    grid-column-end: 3;
  }
}
@media only screen and (max-width: 900px) {
  .gridCont2{
    grid-template-columns: 98%;
    grid-row-gap: 5px;
  }
  .grid_1{
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .grid_2{
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .grid_3{
    grid-column-start: 1;
    grid-column-end: 2;
  }
  h5{
    font-size: 28px;
  }
  .curator_name{
    font-size: 25px;
  }
}
