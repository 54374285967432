.cards{
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardbox{
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon{
    margin-bottom: 1rem;
}

.contact {
    background-color: rgb(11, 9, 28);
}

.location-text {
    color: #9D28EB;
    font-size: 22px;
}

.map {
    border-radius: 8px;
    border: 5px solid #9D28EB;
}
.lead {
    color: white;
}