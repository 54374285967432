@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600&display=swap');

.card-circle{
    text-align:center;
     background: #841cca;    
     border:10px solid transparent;
     color:#fff;
     Font-family: Montserrat;
     padding:10px;
     border-radius:50%;
     border: solid 7px #610195;
}
 .card-circle .card-title{
    font-weight:600;
     font-size:12px;
}
 .card-circle .card-text{
    font-weight:400;
    font-size: 8px;
}
 .card-circle .card-icon i{
     font-size:50px;
     display:block;
}
 .card-circle:nth-child(2){
    background:#142850;
}
 /* .card-circle .btn{
    Font-family: Montserrat;
    background:transparent;
     border:1px solid #fff;
     text-transform:uppercase;
     padding:5px 30px;    
     border-radius:0px;
     Font-weight:600;
} */
.card-circle .btn,.card-circle .card-icon i,.card-circle{
   transition: all ease-in-out 0.2s;
}
 /* .card-circle:hover .btn{
    background:#00A8CC;
     color:#fff;
   border: 1px solid transparent;
}
 .card-circle .btn:hover{
    transform: scale(1.1);
} */
 .card-circle:hover{
    border:10px solid #ffffff;
}
 .card-circle:hover i {
   text-shadow: 0px -1px 10px #C780FA;
    transform:scale(1.2);
}

.row{
    
    justify-content: center;
    align-items: center;
}
 /* @media only screen and (min-width: 1200px) { */
     .card-circle:nth-child(3){
         margin-left:-40px;
         z-index:0;
    }
     .card-circle{
         width:190px;
         height:190px;
    }
     .card-circle:nth-child(2){
         margin-left:-20px;
         box-shadow: 1px 2px 20px 8px rgba(241, 235, 235, 0.12);
         transform: scale(1.2);
         z-index:1;
    }
/* } */
