* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  font-family: "Lato", sans-serif;
}

.item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 30px;
  transition: all 0.3s;
  position: relative;
  text-align: center;
  border: solid 5px #9D28EB;
  color: black;
  background-color: white;
  width: 190px;
  height:120px;
  align-items: center;
  justify-content: center;
}

.item img{
  width: 190px;
  object-fit: contain;
}
.item2 {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 30px;
  transition: all 0.3s;
  position: relative;
  text-align: center;
  border: solid 5px #9D28EB;
  color: black;
  background-color: white;
  width: 150px;
  height:100px;
  align-items: center;
  justify-content: center;
}

.item2 img{
  width: 150px;
  object-fit: contain;
}
.item:hover {
  transform: translateY(-1.1rem);
}

.item-header {
  text-align: center;
  align-items: center;
  margin:0.5rem 2rem;
}

.item-title {
  font-size: 2rem;
  font-weight: 600;
}
.item-para {
  font-size: 12px;
}

/* TABLETS */
@media (max-width: 950px) {

  .features {
    grid-template-columns: 1fr 1fr;
    max-width: 60rem;
  }

  .item-title {
    font-size: 3rem;
  }

  .item-text {
    font-size: 2rem;
  }
}

/* MOBILES */
@media (max-width: 650px) {
  .features {
    grid-template-columns: 1fr;
    max-width: 30rem;
  }
}