.pipe {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.about {
  background-color: rgb(11, 9, 28);
}

.x {
  color: #9D28EB;
}
.about_top{
  align-items: flex-start !important;
}