@tailwind base;
@tailwind components;
@tailwind utilities;
$danger: #e62b1e;
$dark: #202020;
@import "~bootstrap/scss/bootstrap.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: black;
}

.scale-on-hover:hover {
  transition: 0.5s;
  transform: scale(1.02);
}
.h1 {
  text-transform: uppercase;
  font-weight: bold !important;
}
.h2 {
  border-left: 5px solid #e62b1e;
  text-transform: uppercase;
  font-weight: bold !important;
}
.container-maint {
  width: 380px;
  padding: 0;
  margin: 10% auto 0 auto;
  -ms-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  position: releative;
  overflow: hidden;
}

.logo-container {
  margin: 0;
  padding: 0;
  width: 100%;
  display: block;
  position: relative;
  height: 260px;
  overflow: hidden;
}

.message {
  margin: 0;
  padding: 20px 0 40px 0;
  text-align: center;
}

h1 {
  line-height: 1.25;
  font-family: "Montserrat", sans-serif;
}

p {
  margin-top: 20px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
