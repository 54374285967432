.hero-section {
  background-color: rgb(11, 9, 28);
  color: white;
}

.benefit-image-div {
  margin: auto;
  width: 100px;
  height: 100px;
  background: linear-gradient(180deg, #ff7272 0%, #de0000 100%);
  border-radius: 100%;
  padding: 15px;
}
.benefit {
  width: 200px;
  height: 200px;
  display: inline-block;
  justify-content: center;
  align-content: center;
  padding: 2px;
  word-wrap: normal;
  text-align: center;
  margin-left: 2px;
  margin-top: 20px;
  vertical-align: middle;
}
.benefit-heading {
  margin-top: 3px;
  font-size: 15px;
  font-weight: 700;
  font: outfit;
}
.benefit-text {
  font-size: 15px;
  font: outfit;
  word-wrap: break-word;
  font-weight: 500;
}
.second-part {
  text-align: center;
}
.branding-list {
  display: grid;
  grid-template-columns: 300px 300px 300px 300px;
  grid-template-rows: 210px 210px;
  text-align: center;
  justify-content: center;
}
.brand {
  background: linear-gradient(120.28deg, #ff7272 2.7%, #de0000 97.28%);
  padding-top: 10%;
  width: 258px;
  height: 198px;

  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brand > h6 {
  vertical-align: middle;
}
.sponser-list {
  display: inline-flexbox;
  vertical-align: middle;
}
.sponsor-image-div {
  width: 254px;
  height: 160px;
  display: inline-block;
}
.sponsor-image {
  width: 100%;
  height: auto;
  display: inline-flexbox;
  border-radius: 20px;
}

/* Font */
@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700");

/* Design */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  background-color: rgb(11, 9, 28);
  color: white;
}

body {
  color: #272727;
  font-family: "Quicksand", serif;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0;
  /* padding: 1rem; */
}

.main {
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
}
.title {
  padding-top: 50px;
  padding-bottom: 50px;
}

.sub-title {
  padding: 50px;
}

h2 {
  text-align: center;
  font-size: 42px;
}

h1 {
  font-size: 78px;
  font-weight: 800;
  text-align: center;
}

.cards-cards {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 80px;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.cards_item {
  display: flex;
  padding: 1rem;
}

.branding-section {
  background-color: rgb(11, 9, 28)
}

.branding {
  color: white;
  padding-bottom: 50px;
}

.brand-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.brand-row {
  display: flex;
  flex-direction: row;
  padding: 30px;
}

.title-sub {
  color: #9D28EB;
}

.brand-card {
  display: flex;
  padding-left: 3rem;
  padding-right: 3rem;
}

.sponsors-section {
  background-color: rgb(11, 9, 28);
  color: white;
}

@media only screen and (min-width: 768px) and (max-width: 1080px) {
  h1 {
    font-size: 60px;
  }
  .cards-cards {
    display: grid;
    grid-template-columns: 110px 110px 110px 110px;
    grid-template-rows: 200px 200px;
    align-items: center;
  }
  .cards_item:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .cards_item:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .cards_item:nth-child(3) {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .cards_item:nth-child(4) {
    grid-column-start: 2;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .cards_item:nth-child(5) {
    grid-column-start: 4;
    grid-column-end: 6;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .cards_item {
    justify-content: center;
    align-items: center;
  }
  .sponsers_item:nth-child(4) {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .brand-row {
    display: grid;
    grid-template-columns: 250px 250px;
    grid-template-rows: 200px 200px;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .brand-card {
    justify-content: center;
    align-items: center;
  }
  .brand-card:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .brand-card:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .brand-card:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .brand-card:nth-child(4) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .brand-card:nth-child(5) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .brand-card:nth-child(6) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .brand-card:nth-child(7) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .brand-card:nth-child(8) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

@media only screen and (max-width: 767.5px) {
  h1 {
    font-size: 55px;
  }
  .cards-cards {
    display: grid;
    grid-template-rows: 200px 200px 200px 200px 200px;
    align-items: center;
  }
  .cards_item:nth-child(1) {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .cards_item:nth-child(2) {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .cards_item:nth-child(3) {
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .cards_item:nth-child(4) {
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .cards_item:nth-child(5) {
    grid-row-start: 5;
    grid-row-end: 6;
  }

  .cards_item {
    justify-content: center;
    align-items: center;
  }
  .sponsers_cards {
    grid-template-rows: 200px 200px 200px 200px;
  }
  .brand-row {
    display: grid;
    grid-template-rows: 200px 200px 200px 200px;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .brand-card {
    justify-content: center;
    align-items: center;
  }
  .brand-card:nth-child(1) {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .brand-card:nth-child(2) {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .brand-card:nth-child(3) {
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .brand-card:nth-child(4) {
    grid-row-start: 4;
    grid-row-end: 5;
  }
  .brand-card:nth-child(5) {
    grid-row-start: 1;
    grid-row-end: 2;
  }
  .brand-card:nth-child(6) {
    grid-row-start: 2;
    grid-row-end: 3;
  }
  .brand-card:nth-child(7) {
    grid-row-start: 3;
    grid-row-end: 4;
  }
  .brand-card:nth-child(8) {
    grid-row-start: 4;
    grid-row-end: 5;
  }
}
